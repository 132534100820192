import React, { Component } from "react";
import HeaderTwo from "../../components/HeaderTwo";

import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/franchise";

import OnlineSupportBox from "../../components/OnlineSupport";

const OnlineSupport = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper about-page">
        {/* Header 2 */}
        <HeaderTwo />

        {/* Tab Section */}
        <OnlineSupportBox heading={'General Information'} />

        {/* Footer 2 */}
        <FooterTwo />
      </div>
    </Styles>
  );
};

export default OnlineSupport;
