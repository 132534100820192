import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";
import franchise from "../assets/images/franchise.jpg";
import "./style.css"
import { colors } from "./common/element/elements.js";
import help from '../assets/images/help.jpg'


class HelpCenterBox extends Component {
  render() {
    const { heading } = this.props;

    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
        <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={help}
              alt="Franchise"
              style={{
                width: "50%",
                height: "300px",
                marginTop: 20,
                marginBottom: 30,
                borderRadius: 10
              }}
            />
          </div>
          <Container style={{ width: "50%", color: "black" }}>

            <h5 className="tab-title">Help Center</h5>

            <p
              className="tab-desc"
              style={{ textAlign: "justify", fontSize: 14, marginTop: 10, color: colors.text2 }}
            >
Welcome to the Study Concerns International (SCI) Help Center! Our dedicated team of professionals is here to assist you in choosing the right academic program and institution to achieve your goals. From admissions guidance to post-admission support, we’re committed to your success. Contact us at info@studyconcerns.com or WhatsApp +923222022121—one of our team members will get back to you soon!
              </p>
            
          </Container>
        </section>
      </Styles>
    );
  }
}

export default HelpCenterBox;
