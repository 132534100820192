import course1 from "../../assets/images/course-1.jpg";
import software from "../../assets/images/software.jpg";
import energySystem from "../../assets/images/energy_system.jpg";
import environmental from "../../assets/images/enviroment_system.png";
import oilGas from "../../assets/images/oil_gas.jpg";
import safetyRisk from "../../assets/images/saftey_risk.jpg";
import internationalBuis from "../../assets/images/international_buis.jpg";
import artDesign from "../../assets/images/art&design.jpg";
import changeManagement from "../../assets/images/changeManagement.jpg";
import dataAnalytics from "../../assets/images/dataAnalytics.jpg";
import financialAnalytics from "../../assets/images/financialAnalytics.jpg";
import management from "../../assets/images/management.jpg";
import technologyManagement from "../../assets/images/technologyManagement.jpg";
import businessHealth from "../../assets/images/businessHealth.jpg";
import it from "../../assets/images/it.jpg";
import it2 from "../../assets/images/it2.jpg";
import artDesignEng from "../../assets/images/artDesignEng.jpg";
import artDesign2 from '../../assets/images/artDesign2.jpg';
import health1 from '../../assets/images/health1.jpg';
import health3 from '../../assets/images/health3.jpg';
import eng2 from '../../assets/images/eng2.jpg';
export const dataList = [
  {
    id: "1",
    targetId: "desi",
    imgUrl: course1,
    authorImg: "author.jpg",
    authorName: "John Doe",
    authorCourses: "13 Courses",
    price: "$20",
    courseTitle: "MASc - Computer Engineering",
    courseDesc:
      "Prepares for Careers In Telecom, IT, Software, and Hardware Design, Providing Balanced Expertise In Computer Hardware, Software, and Key Applications.",
    courseLink: "/course-details",
    overView:"The Master of Applied Science (MASc) in Computer Engineering is a course-based graduate program which prepares its graduates for further post-graduate study or for careers as Computer Engineers in sectors such as telecommunications, information technology, software development, and digital hardware design. The degree will provide participants with a balanced background in computer hardware and software and an in-depth knowledge of important applications areas, such as software development, hardware design, telecommunication systems, computer networks, and parallel computing."
  },
  {
    id: "2",
    targetId: "deve",
    imgUrl: internationalBuis,
    authorImg: "author.jpg",
    authorName: "Jara Sen",
    authorCourses: "21 Courses",
    price: "$13",
    courseTitle: "MBA - In International Business",
    courseDesc:
      "This program prepares leaders to manage diverse teams, lead global organizations, and navigate the fast-changing landscape of global business.",
    courseLink: "/course-details",
  },
  {
    id: "3",
    targetId: "seo",
    imgUrl: dataAnalytics,
    authorImg: "author.jpg",
    authorName: "Kim Chang",
    authorCourses: "26 Courses",
    price: "$19",
    courseTitle:
      "Master of Science in Big Data Analytics - Applied Modelling and Quantitative Methods",
    courseDesc:
      "You will be uniquely prepared with tools to work with and analyze complex data sets in sciences and business.",
    courseLink: "/course-details",
  },
  // {
  //   id: "4",
  //   targetId: "prog",
  //   imgUrl: "course-4.jpg",
  //   authorImg: "author.jpg",
  //   authorName: "Jim Hunt",
  //   authorCourses: "09 Courses",
  //   price: "$15",
  //   courseTitle: "Zero to Hero in Python 3",
  //   courseDesc:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur accusamus hic ea in autem debitis minima.",
  //   courseLink: "/course-details",
  // },
  {
    id: "5",
    targetId: "desi",
    imgUrl: software,
    authorImg: "author.jpg",
    authorName: "Sara Kim",
    authorCourses: "17 Courses",
    price: "$21",
    courseTitle: "MASc - Software Engineering",
    courseDesc: `
Specialize in Software Engineering: Gain core knowledge for roles in software development, systems architecture, design, and related areas.`,
    courseLink: "/course-details",
  },
  // {
  //   id: "6",
  //   targetId: "deve",
  //   imgUrl: dataAnalytics,
  //   authorImg: "author.jpg",
  //   authorName: "Shon Doe",
  //   authorCourses: "31 Courses",
  //   price: "$24",
  //   courseTitle:
  //     "Master of Science in Big Data Analytics - Applied Modelling and Quantitative Methods",
  //   courseDesc:
  //     "You will be uniquely prepared with the tools and techniques you require to work with and analyze today’s increasingly complex data sets in all areas of the sciences, as well as the business world.",
  //   courseLink: "/course-details",
  // },
  {
    id: "7",
    targetId: "desi",
    imgUrl: energySystem,
    authorImg: "author.jpg",
    authorName: "Sara Kim",
    authorCourses: "17 Courses",
    price: "$21",
    courseTitle: "MASc - Energy Systems Engineering",
    courseDesc:
      "Explore Energy Systems Engineering to drive innovation in sustainable energy solutions, addressing global demands and environmental concerns.",
    courseLink: "/course-details",
  },
  {
    id: "8",
    targetId: "desi",
    imgUrl: environmental,
    authorImg: "author.jpg",
    authorName: "Sara Kim",
    authorCourses: "17 Courses",
    price: "$21",
    courseTitle: "Environmental Systems Engineering and Management",
    courseDesc:
      "Embark on a transformative journey in Environmental Engineering. Gain comprehensive education and interdisciplinary expertise to address complex environmental issues.",
    courseLink: "/course-details",
  },
  {
    id: "9",
    targetId: "desi",
    imgUrl: oilGas,
    authorImg: "author.jpg",
    authorName: "Sara Kim",
    authorCourses: "17 Courses",
    price: "$21",
    courseTitle: "MASc - Oil and Gas Engineering",
    courseDesc:
      "Explore the oil and gas industry with our Master in Oil and Gas Engineering program, covering sustainable development, environmental protection, and more.",
    courseLink: "/course-details",
  },
  {
    id: "22",
    targetId: "desi",
    imgUrl: eng2,
    authorImg: "author.jpg",
    authorName: "Sara Kim",
    authorCourses: "17 Courses",
    price: "$21",
    courseTitle: "Master in Sustainable Infrastructure Engineering",
    courseDesc:
      "This program equips them with the necessary skills to develop, operate, and maintain sustainable infrastructure systems - buildings, bridges, dams, drinking water, drainage and sewer systems, waste management, power supply facilities, etc.",
    courseLink: "/course-details",
  },
  {
    id: "10",
    targetId: "desi",
    imgUrl: safetyRisk,
    authorImg: "author.jpg",
    authorName: "Sara Kim",
    authorCourses: "17 Courses",
    price: "$21",
    courseTitle: "MASc - Safety and Risk Engineering",
    courseDesc:
      "Master in Safety and Risk Engineering for professionals, offering skills for real-world applications in hazardous facilities, and introducing recent analytical techniques.",
    courseLink: "/course-details",
  },
  {
    id: "11",
    targetId: "deve",
    imgUrl: technologyManagement,
    authorImg: "author.jpg",
    authorName: "Jara Sen",
    authorCourses: "21 Courses",
    price: "$13",
    courseTitle:
      "MA In Leadership & MSc In Information Technology and Management",
    courseDesc:
      "Join the Dual Track Master's program to earn TWU's MA in Leadership and Belhaven University's MS in Information Technology Management simultaneously.",
    courseLink: "/course-details",
  },
  {
    id: "12",
    targetId: "deve",
    imgUrl: businessHealth,
    authorImg: "author.jpg",
    authorName: "Jara Sen",
    authorCourses: "21 Courses",
    price: "$13",
    courseTitle:
      "MA In Leadership - Specialization In Education, Business, or Health",
    courseDesc:
      "This program develops emerging leaders as confident and effective servant-leaders for the 21st Century, focusing on Transformational Servant and Values-Based Leadership.",
    courseLink: "/course-details",
  },
  {
    id: "13",
    targetId: "deve",
    imgUrl: changeManagement,
    authorImg: "author.jpg",
    authorName: "Jara Sen",
    authorCourses: "21 Courses",
    price: "$13",
    courseTitle: "Master of Management -  Strategic Change Management",
    courseDesc:
      "The Strategic Change Management stream of the Master of Management program prepares graduates for managerial careers in evolving business environments.",
    courseLink: "/course-details",
  },
  {
    id: "14",
    targetId: "deve",
    imgUrl: management,
    authorImg: "author.jpg",
    authorName: "Jara Sen",
    authorCourses: "21 Courses",
    price: "$13",
    courseTitle: "Master of Management ",
    courseDesc:
      "This master's program offers a competitive edge in the job market with a broad spectrum of business disciplines, from accounting to strategic management.",
    courseLink: "/course-details",
  },
  {
    id: "15",
    targetId: "seo",
    imgUrl: financialAnalytics,
    authorImg: "author.jpg",
    authorName: "Kim Chang",
    authorCourses: "26 Courses",
    price: "$19",
    courseTitle:
      "Master of Science in Big Data Financial Analytics - Applied Modelling and Quantitive Methods afdsdfsdf sdf",
    courseDesc:
      "Focus on financial analysis theories and techniques for careers in equity analysis, investment banking, portfolio management, and other financial sector opportunities.",
    courseLink: "/course-details",
  },
  {
    id: "16",
    targetId: "seo",
    imgUrl: it,
    authorImg: "author.jpg",
    authorName: "Kim Chang",
    authorCourses: "26 Courses",
    price: "$19",
    courseTitle: "Master of Science in Forensic Science",
    courseDesc: `Enhance your expertise in forensic science with our professional program. Gain hands-on experience, develop communication skills, and explore research-practice nexus.`,
    courseLink: "/course-details",
  },
  {
    id: "17",
    targetId: "arts",
    imgUrl: artDesignEng,
    authorImg: "author.jpg",
    authorName: "Kim Chang",
    authorCourses: "26 Courses",
    price: "$19",
    courseTitle: "Master of Arts In English ",
    courseDesc: `
Earn a Master’s in English (Public Texts) at Trent in just a year. Explore careers in communications, editing, teaching, or scholarship.`,
    courseLink: "/course-details",
  },
  {
    id: "18",
    targetId: "arts",
    imgUrl: artDesign,
    authorImg: "author.jpg",
    authorName: "Kim Chang",
    authorCourses: "26 Courses",
    price: "$19",
    courseTitle: "Master of Arts in Sustainability Studies",
    courseDesc:
      "Lead change through Sustainability Studies at the University, addressing environmental, economic, social, and cultural challenges with interdisciplinary exploration",
    courseLink: "/course-details",
  },
  {
    id: "19",
    targetId: "arts",
    imgUrl: artDesign2,
    authorImg: "author.jpg",
    authorName: "Kim Chang",
    authorCourses: "26 Courses",
    price: "$19",
    courseTitle: "Master of Arts Management with 4 Months Paid Co-op",
    courseDesc:
      "The Master of Management program offers three professional streams. The program is 16 months followed by a workplace internship in the fourth term.",
    courseLink: "/course-details",
  },
  
  {
    id: "20",
    targetId: "health",
    imgUrl: health1,
    authorImg: "author.jpg",
    authorName: "Kim Chang",
    authorCourses: "26 Courses",
    price: "$19",
    courseTitle: "Master of Instrumental Chemical Analysis with 4 Months Paid Co-op",
    courseDesc:
      "In this 12-month Master's in Instrumental Chemical Analysis with co-op, students have the opportunity to get hands-on, industry experience through paid.",
    courseLink: "/course-details",
  },
  {
    id: "20",
    targetId: "health",
    imgUrl: health3,
    authorImg: "author.jpg",
    authorName: "Kim Chang",
    authorCourses: "26 Courses",
    price: "$19",
    courseTitle: "Master of Management: Health Care Management with 4 months",
    courseDesc:
      "Prepares graduates for managerial careers in dynamic and complex healthcare sector.......",
    courseLink: "/course-details",
  },
  {
    id: "21",
    targetId: "seo",
    imgUrl: it2,
    authorImg: "author.jpg",
    authorName: "Kim Chang",
    authorCourses: "26 Courses",
    price: "$19",
    courseTitle: "Master of Science in Applied Modelling: Data Science & Analytics",
    courseDesc:
      "Students learn the tools and techniques needed to handle and analyze complex data sets across various scientific and social science fields.",
    courseLink: "/course-details",
  },
];
