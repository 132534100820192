import React, { Component } from "react";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import AboutUs from "../../components/AboutUs";
import IconBox from "../../components/IconBox";
import TabBox from "../../components/TabBox";
import TestimonialSlider from "../../components/TestimonialSlider";
import FaqEvent from "../../components/FaqEvent";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/franchise";
import FranchiseBox from "../../components/Franchise";
import GeneralBox from "../../components/General";

const General = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper about-page">
        {/* Header 2 */}
        <HeaderTwo />

        {/* Tab Section */}
        <GeneralBox heading={'General Information'} />

        {/* Footer 2 */}
        <FooterTwo />
      </div>
    </Styles>
  );
};

export default General;
